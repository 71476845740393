import { TWO_HOURS_IN_MILLISECONDS, TWO_MINUTES_IN_MILLISECONDS } from 'mdc-constants';
import { useGraphQLClient } from 'providers';
import { useMemo } from 'react';
import { useGetCurrentUserData } from '../graphql';
import useAccessToken from './useAccessToken';

export default function useCurrentUser() {
  const { data: accessToken } = useAccessToken();

  const { graphQLClient } = useGraphQLClient(accessToken);

  const queryInfo = useGetCurrentUserData(
    graphQLClient,
    {},
    {
      refetchInterval: TWO_HOURS_IN_MILLISECONDS,
      cacheTime: TWO_HOURS_IN_MILLISECONDS,
      staleTime: TWO_MINUTES_IN_MILLISECONDS,
      enabled: !!accessToken,
    },
  );

  const alertsLimitReached = useMemo<boolean>(() => {
    const currentAvailableAlerts = queryInfo?.data?.authUser?.subscription?.available_active_alert_slots || 0;

    return !(currentAvailableAlerts > 0);
  }, [queryInfo?.data?.authUser?.subscription?.available_active_alert_slots]);

  return {
    ...queryInfo,
    alertsLimitReached,
  };
}
